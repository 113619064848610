import { useState } from 'react'
import imageBackground from '../../assets/imgs/search.png'
import { SearchIconMobile } from '../icons/common/searchMobile'
import { ButtonMobile } from './components/ButtonMobile'
import './style.css'
import { useApp } from '../../context/app'

export function SearchMobile() {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [inputSearch, setInputSearch] = useState<string>('')

	return (

		<div className="SearchMobile">

			<div
				className="SearchMobile-container"
				style={{
					backgroundImage: `url(${imageBackground})`,
				}}
			>

				<div className="SearchMobile-box" >

					<div className="SearchMobile-lente">

						<form className="SearchMobile-form"
							action={`${domain}busca/${inputSearch}`}
							method='POST'
						>

							<input
								name='search'
								type='text'
								onChange={e => setInputSearch(e.target.value)}
								placeholder='Procure no portal'
								className="SearchMobile-input"
							/>

							<button className="SearchMobile-button"
								type='submit'
							><SearchIconMobile /></button>

						</form>

						<div className="SearchMobile-btn-campo">

							<ButtonMobile
								href='https://servicos.itajai.sc.gov.br/servico/23-aprova_digital.html'
							>Aprova Digital</ButtonMobile>

							<ButtonMobile
								href='https://nfse.itajai.sc.gov.br/'
							>NFS-e</ButtonMobile>

							<ButtonMobile
								href='https://intranet2.itajai.sc.gov.br/jornal-municipio/portal/'
							>Jornais</ButtonMobile>

							<ButtonMobile
								href='https://webmail.itajai.sc.gov.br/'
							>Webmail</ButtonMobile>

						</div>

					</div>

				</div>

			</div>

		</div>

	)

}