import { IContact } from "../../../../interfaces/contact"

interface Props {
    item: IContact
    index: number
}

export function Item({ item, index }: Props) {
	
    return (
	
        <div className="pages-Contact-Item">
            <div className="pages-Contact-Item-nome">{item.name}</div>
            <div className="pages-Contact-Item-telefone">{item.phone.replace(',', ' ou ')}</div>
        </div>
		
    )
	
}