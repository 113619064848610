import { useEffect, useState } from 'react'
import { api, urls } from '../../services/api'
import { IModal } from '../../interfaces/modal'
import { useApp } from '../../context/app'
import { Fechar } from '../icons/common/fechar'
import './style.css'

export function Popup() {

	const { configApiURL } = useApp()
	const [modalItem, setModal] = useState<IModal>()
	const [clicked, setClicked] = useState('Popup on');

	const fecharPopup = () => {
		clicked ? setClicked('Popup') : setClicked('Popup on')
	}

	useEffect(() => {
		(async () => {
			try {

				const responseModal = await api.get<IModal>(urls.Modal)

				//console.log('responseModal.data:', responseModal.data)

				setModal(responseModal.data)

			} catch (error) {
				console.log('responseModal.data.error:', error)
			}
		})()
	}, [])

	console.log( 'modalItem', modalItem )

	const apiUrl = `${configApiURL}public/`;

	return (

		<div className={clicked || "Popup on"} >

			<div
				className="Popup-escurecer"
				onClick={fecharPopup}
			>
				<div className="Popup-escurecer-fechar"><Fechar /></div>
			</div>

			{
				!!modalItem?.content &&

				<div
					className={modalItem?.transparence ? "Popup-item transparente" : "Popup-item"}
					dangerouslySetInnerHTML={{ __html: modalItem?.content.replaceAll('src="/public/', `src="${apiUrl}`).toString() }}
				></div>

			}

		</div>

	)

}