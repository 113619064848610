import { IContact } from "../../../../interfaces/contact"
import { Item } from "./item"
import { Title } from "./title"

interface Props {
	items: IContact[]
}
export function ListContact({ items }: Props) {
	
	return (
	
		<div className="pages-Contact-ListContact">
			<Title />
			{items.map((item, index) => <Item key={index} item={item} index={index} />)}
		</div>
		
	)
}