import { useApp } from "../../../context/app"
import { IMenu, ISection } from "../../../interfaces/menu"

interface Props {
	subMenu: IMenu
}
export function SubMenu({ subMenu }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	//console.log('Header-subMenu.tsx ln 9 - subMenus:')
	//console.log(subMenu.subMenus)

	return (
		<div className="SubItemMenuNav">

			<div className="SubItemMenuNav-titulo">{subMenu.titulo}</div>

			<ul>
				{
					subMenu.subMenus?.map((item) => {

						//console.log('Header-subMenu ln 29 - item:')
						//console.log(item)

						let subMenu_link = '#'

						if (
							item.link
							&& (
								item.ref_cod_menu_tipo_componente == 13
								|| item.ref_cod_menu_tipo_componente == 12
							)
						) { subMenu_link = item.link }

						if (
							item.ref_cod_artigo
							&& (
								item.ref_cod_menu_tipo_componente == 2
								|| item.ref_cod_menu_tipo_componente == 14
							)
						) { subMenu_link = domain + 'e/' + item.ref_cod_artigo }

						if (
							item.ref_cod_entidade
							&& (
								item.ref_cod_menu_tipo_componente == 15
								|| item.ref_cod_menu_tipo_componente == 19
							)
						) { subMenu_link = domain + 'a/' + item.ref_cod_entidade }

						//console.log('Header-subMenu ln 56 - subMenu_link:')
						//console.log(subMenu_link)

						let subMenu_target = '_SELF'
						if (item.nova_janela == true) { subMenu_target = '_BLANK' }

						return (
							<li>
								<a
									//href={`${domain}${!!item.ref_cod_artigo ? 'e/' + item.ref_cod_artigo : 'a/' + item.ref_cod_entidade}`} 
									href={`${subMenu_link}`}
									target={`${subMenu_target}`}
									className="hover-submenu-item-itemMenuNav"
								>
									• {item.titulo}
								</a>
							</li>
						)
					})
				}
			</ul>
		</div>
	)
}