import Carousel from 'react-bootstrap/Carousel';
import { useEffect, useState } from "react"
import { api, urls } from "../../services/api"
import { IBanner } from "../../interfaces/banner"
import { useApp } from '../../context/app';
import './style.css';

//https://react-bootstrap.netlify.app/docs/components/carousel/

interface Props {
	mobile: boolean
}

export function Banner({ mobile }: Props) {
	const { configApiURL } = useApp()
	const [banners, setBanner] = useState<IBanner[]>([])
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex: any) => {
		setIndex(selectedIndex);
	};

	useEffect(() => {
		(async () => {

			try {

				const response = await api.get<IBanner[]>(`${urls.Banners}?periferico=${mobile ? 'Mb' : 'PC'}`)

				if (mobile) {

					setBanner(response.data.filter(item => item.banner_periferico == 'Mb'))

				}
				else {

					setBanner(response.data.filter(item => item.banner_periferico == 'PC'))

				}

			} catch (error) {
				console.log('src/components/Banner ln 43 - Erro: ', error)
			}

		})()
	}, [])

	//console.log('banners', banners)

	return (

		<div className="Banner">

			<div className="Banner-container">

				{

					banners.length > 0 ? (

						<Carousel
							className="slide"
							fade
							controls={false}
							indicators={false}
						>
							{

								banners.map((banner, index) => (

									<Carousel.Item key={index}>
										<a
											href={banner.link}
											target='_blank'
										>
											<img
												src={`${configApiURL}public/portaladm-pmitajai/banners/${banner.nome_arquivo.split('banners/')[1]}`}
												className="d-block w-100 Banner-img"
											/>
										</a>

									</Carousel.Item>

								))

							}
						</Carousel>
					)
					: (
						<></>
					)

				}

			</div>

		</div>

	);

}