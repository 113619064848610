import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { useEffect, useState } from "react";
import { api, urls } from "../../services/api";
import { MapSite } from "../../components/MapSite";
import { ItemCategorie } from "./components/itemCategorie";
import FlatList from "flatlist-react/lib";
import { IArticle } from "../../interfaces/article";
import { IMenuItem, IMenu } from "../../interfaces/menu";
import { Footer } from "../../components/Footer";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { ServicesAccordion } from "../../components/ServicesAccordion";
import { Banner } from "../../components/Banner";
import { ISearch } from "../../interfaces/search";
import { SubTitlePage } from "../../components/SubTitlePage";
import VLibras from '@djpfs/react-vlibras';
import { Fechar } from '../../components/icons/common/fechar';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function SearchScreen() {
	
	const { configDomainURL, mobile, visible } = useApp()
	const domain = configDomainURL
	let { searchText } = useParams();

	const [search, setSearch] = useState<ISearch>({
		artigos: [],
		noticias: [],
		servicos: []
	})

	const [article, setArticle] = useState<IArticle[]>([])
	const [listMenu, setMenuList] = useState<IMenu[]>([])
	const [finded, setFinded] = useState<boolean>(false)
	const [loading, setLoading] = useState<string>('Carregando...')
	const [buscaValor, setBuscaValor] = useState<string>('')
	const [counterString, setCounterString] = useState<string>('1 Resultado')
	const [counter, setCounter] = useState<number>(0)
	const [countNoticias, setCountNoticias] = useState<number>(0)
	const [countServicos, setCountServicos] = useState<number>(0)
	const [countArtigos, setCountArtigos] = useState<number>(0)
	
	setTimeout(function() { setLoading('Nenhuma informação encontrada.') }, 3000);

	const getSearchByText = async (inputSearch: string) => {

		try {

			inputSearch = decodeURIComponent(inputSearch)

			const response = await api.get(urls.Pesquisa + inputSearch)

			//console.log('response.data', response.data)
			//console.log('inputSearch', inputSearch)
			
			setBuscaValor( inputSearch )

			if (
				(
					response.data.artigos
					&& response.data.artigos.length > 0
				)
				|| (
					response.data.servicos
					&& response.data.servicos.length > 0
				)
				|| (
					response.data.noticias
					&& response.data.noticias.length > 0
				)
			) {

				setSearch({ ...response.data })
				setFinded(true)
				
			}
			
			if( response.data.noticias ){
				
				setCountNoticias( response.data.noticias.length )
				
			}
			
			if( response.data.servicos ){
				
				setCountServicos( response.data.servicos.length )
				
			}
			
			if( response.data.artigos ){
				
				setCountArtigos( response.data.artigos.length )
				
			}
			
		} catch (error) {
			console.log('getSearchByText erro: ', error)
		}
		
	}

	const getArticleById = async (id: number) => {
		try {

			const response = await api.get(urls.Article + id)

			setArticle(response.data)

		} catch (error) {
			console.log('getArticleById ln 81 erro: ', error)
		}
		
	}

	useEffect(() => {
		searchText && getSearchByText(searchText)
	}, [])

	useEffect(() => {
		(async () => {
			const response = await api.get(urls.menus)
			const menu: IMenuItem[] = response.data
			const menuFiltered = menu[0].secoes[0].menus as IMenu[]
			setMenuList([...menuFiltered])
		})()
	}, [])

	useEffect( () => {
		(async () => {
			setCounterString( ( countNoticias+countServicos+countArtigos ) +' Resultados' )
		})()
	}, [( countNoticias+countServicos+countArtigos )])
	
	const handleGetArticle = async (id: number) => {
		getArticleById(id)
	}

	if (listMenu.length < 1) {
		return <></>
	}
	
	const filtrar = ( 
		classe01: any, 
		classe02: any, 
		classe03: any, 
		classe04: any 
	) => {

		if(  document.querySelector( classe01 ) ){ document.querySelector( classe01 ).style.display = 'none'; }
		if(  document.querySelector( classe02 ) ){ document.querySelector( classe02 ).style.display = 'none'; }
		if(  document.querySelector( classe03 ) ){ document.querySelector( classe03 ).style.display = ''; }
		document.querySelector( classe04 ).style.display = '';
		
	}
	
	const filtroReset = ( 
		classe01: any, 
		classe02: any, 
		classe03: any, 
		classe04: any 
	) => {

		if(  document.querySelector( classe01 ) ){ document.querySelector( classe01 ).style.display = ''; }
		if(  document.querySelector( classe02 ) ){ document.querySelector( classe02 ).style.display = ''; }
		if(  document.querySelector( classe03 ) ){ document.querySelector( classe03 ).style.display = ''; }
		document.querySelector( classe04 ).style.display = 'none';
		
	}
	
	//console.log('counter', counter)
	//console.log('buscaValor', buscaValor)
	
	if ( mobile ) {

		return (
			<div className="pages-mobile-Search">

				<HeaderMobile />

				<SearchMobile />

				<TitlePage title={'Busca Geral'} />

				<div className="pages-mobile-Search-container">

					{

						!finded ?

							(

								<div>{loading}</div>

							)

							:

							(

								<>

									{

										search.noticias &&

										<>

											<SubTitlePage title="Notícias" />

											<ul>

												<FlatList

													list={search.noticias}

													renderItem={(item) => (

														<li className="pages-mobile-Search-item">

															<a
																href={`${domain}noticias/${item.cod_noticia}/${item.titulo.replaceAll(' ', '-').toLowerCase()}`}
															>• {item.titulo}</a>

														</li>

													)}

												/>

											</ul>

										</>

									}

									{

										search.servicos &&

										<>

											<SubTitlePage title="Serviços" />

											<ul>

												<FlatList

													list={search.servicos}

													renderItem={(item) => (

														<li className="pages-mobile-Search-item">

															<a
																href={`https://jorge.itajai.sc.gov.br/portaladm-pmitajai/servico/acesso/cod/${item.cod_servico}`}
															>• {item.titulo}</a>

														</li>

													)}

												/>

											</ul>

										</>

									}

									{

										search.artigos &&

										<>

											<SubTitlePage title="Artigos" />

											<ul>

												<FlatList

													list={search.artigos}

													renderItem={(item) => (

														<li className="pages-mobile-Search-item">

															<a
																href={`${domain}e/${item.cod_artigo}`}
															>• {item.titulo}</a>

														</li>

													)}

												/>

											</ul>

										</>

									}

								</>

							)

					}

				</div>

				<ServicesAccordion />

				<Banner mobile={mobile} />

				<div className="pages-mobile-Search-separador" />

				<MapSite />

				<Footer />

				{

					visible &&

					(

						<VLibras forceOnload />

					)

				}

			</div>

		)

	}

	return (
		<div className="pages-Search">

			<Header search route={` Administração`} />

			<TitlePage title={'Busca Geral'} />

			<div className="pages-Search-container">

				{

					!finded ?

					(

						<div className="pages-Search-empty">{loading}</div>
						
					)

					:

					(
						
						<>
						
							<div className="pages-Search-esq">
								
								<div className="pages-Search-filtro-descricao">{buscaValor}</div>
								
								<div className="pages-Search-filtro-counter">{counterString}</div>
								
								<div 
									className="pages-Search-filtro-reset"
									onClick={ () => filtroReset( 
										'.pages-Search-bloco.noticias', 
										'.pages-Search-bloco.servicos', 
										'.pages-Search-bloco.artigos', 
										'.pages-Search-filtro-reset' 
									) }
									style={{ display:"none" }}
								>Retirar filtro...<div className="pages-Search-filtro-reset-x"><Fechar /></div></div>
								
								<div className="pages-Search-filtro-titulo">Categorias</div>
								
								<div 
									className="pages-Search-filtro-item"
									onClick={ () => filtrar( 
										'.pages-Search-bloco.artigos', 
										'.pages-Search-bloco.servicos', 
										'.pages-Search-bloco.noticias', 
										'.pages-Search-filtro-reset' 
									) }
								>Notícias <span className="pages-Search-filtro-claro">({countNoticias})</span></div>
								
								<div 
									className="pages-Search-filtro-item"
									onClick={ () => filtrar( 
										'.pages-Search-bloco.artigos', 
										'.pages-Search-bloco.noticias', 
										'.pages-Search-bloco.servicos', 
										'.pages-Search-filtro-reset' 
									) }
								>Serviços <span className="pages-Search-filtro-claro">({countServicos})</span></div>
								
								<div 
									className="pages-Search-filtro-item"
									onClick={ () => filtrar( 
										'.pages-Search-bloco.noticias', 
										'.pages-Search-bloco.servicos', 
										'.pages-Search-bloco.artigos', 
										'.pages-Search-filtro-reset' 
									) }
								>Artigos <span className="pages-Search-filtro-claro">({countArtigos})</span></div>
								
								<div className="pages-Search-filtro-btn-campo">
									<div className="pages-Search-filtro-btn">Busca Avançada</div>
									<div className="pages-Search-filtro-aviso">(Esta busca é lenta, pois procura dentro da descrição de cada item)</div>
								</div>
								
							</div>

							<div className="pages-Search-dir">
							
								{
									search.noticias &&

									<div className="pages-Search-bloco noticias">

										<SubTitlePage title="Notícias" />

										<FlatList
											list={search.noticias}
											renderItem={(item) => (

												<a
													href={`${domain}noticias/${item.cod_noticia}/${item.titulo.replaceAll(' ', '-').toLowerCase()}`}
													className="pages-Search-item"
												>• {item.titulo}</a>

											)}
											
										/>
										
									</div>
									
								}

								{
									search.servicos &&

									<div className="pages-Search-bloco servicos">

										<SubTitlePage title="Serviços" />

										<FlatList
											list={search.servicos}
											renderItem={(item) => (

												<a
													href={`https://jorge.itajai.sc.gov.br/portaladm-pmitajai/servico/acesso/cod/${item.cod_servico}`}
													className="pages-Search-item"
												>• {item.titulo}</a>

											)}
										/>
										
									</div>
									
								}
								
								{

									search.artigos &&

									<div className="pages-Search-bloco artigos">

										<SubTitlePage title="Artigos" />

										<FlatList
											list={search.artigos}
											renderItem={(item) => (

												<a
													href={`${domain}e/${item.cod_artigo}`}
													className="pages-Search-item"
												>• {item.titulo}</a>

											)}
										/>
										
									</div>
									
								}
								
							</div>
							
						</>
						
					)
					
				}
				
			</div>

			<MapSite />

			<Footer />

			{

				visible &&

				(

					<VLibras forceOnload />

				)

			}
			
		</div>
		
	)

}