import { useApp } from "../../../context/app"
import { IMenu } from "../../../interfaces/menu"

interface Props {
	newsCategorie: IMenu
	idActual: number
	onClick?: () => void
}

export function ItemCategorie({ idActual, newsCategorie, onClick }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	if (!newsCategorie.ref_cod_artigo && !newsCategorie.ref_cod_entidade) {

		return (

			<a
				href={newsCategorie.link}
				target="_blank"
				onClick={onClick}
				className="pages-Article-ItemCategorie-generico"
				style={{
					color: newsCategorie.ref_cod_artigo == idActual ? 'var(--azul_itajai02)' : 'var(--cinza100)',
					fontWeight: newsCategorie.ref_cod_artigo == idActual ? '600' : '400',
				}}
			>{newsCategorie.titulo}</a>

		)

	}

	return (

		<a
			href={`${domain}${newsCategorie.ref_cod_artigo ? 'e/' + newsCategorie.ref_cod_artigo : 'a/' + newsCategorie.ref_cod_entidade}`}
			onClick={onClick}
			className="pages-Article-ItemCategorie"
			style={{
				color: newsCategorie.ref_cod_artigo == idActual ? 'var(--azul_itajai02)' : 'var(--cinza100)',
				fontWeight: newsCategorie.ref_cod_artigo == idActual ? '600' : '400',
			}}
		>{newsCategorie.titulo}</a>

	)
}