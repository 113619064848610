import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { useEffect, useState } from "react";
import { api, urls } from "../../services/api";
import { INewsComplet } from "../../interfaces/news";
import { SubTitlePage } from "../../components/SubTitlePage";
import { ListImages } from "../../components/ListImages";
import { MapSite } from "../../components/MapSite";
import { OtherNews } from "../../components/OtherNews";
import { ItemCategorie } from "./components/itemCategorie";
import FlatList from "flatlist-react/lib";
import { IArticle } from "../../interfaces/article";
import { IMenuItem, IMenu } from "../../interfaces/menu";
import { Footer } from "../../components/Footer";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { ServicesAccordion } from "../../components/ServicesAccordion";
import { Banner } from "../../components/Banner";
import { IEntity } from "../../interfaces/entity";
import VLibras from '@djpfs/react-vlibras';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function EntityScreen() {
	
	let { id } = useParams();
	const { mobile, menuMobile, visible, configApiURL } = useApp()
	const [entity, setEntity] = useState<IEntity>()
	const [listMenu, setMenuList] = useState<IMenu[]>([])

	const getEntityById = async (id: number) => {
		
		try {
			
			const response = await api.get(urls.Entity + id)
			//console.log('getEntityById response.data', response.data)
			setEntity(response.data)
			
		} 
		catch (error) {
			console.log('src/pages/Entity getEntityById Erro:', error)
		}
		
	}

	useEffect(() => {

		id && getEntityById(parseInt(id))
		
	}, [])

	useEffect(() => {
		(async () => {
			const response = await api.get(urls.menus)
			const menu: IMenuItem[] = response.data
			var menuFiltered: IMenu[] = []
			var idSection = 0
			menu[0].secoes.map(itemSecoes => {
				itemSecoes.menus?.map(itemMenu => {
					if (parseInt(id || '0') == itemMenu.ref_cod_entidade) {
						idSection = itemMenu.ref_cod_menu_secao || 0
					}
				})
				if (itemSecoes.cod_menu_secao == idSection) {
					menuFiltered = itemSecoes.menus as IMenu[]
				}
			})
			setMenuList([...menuFiltered])
		})()
	}, [])

	const handleGetArticle = async (id: number) => {
		
		//console.log(id)
		getEntityById(id)
		
	}
	
	//console.log('entity', entity?.descricao)
	
	const apiUrl = configApiURL.replace("portaladm-pmitajai/api/", "public/")
	const content = entity?.descricao.replaceAll('src="/', `src="${apiUrl}`)
	
	return (
		<div className="pages-Entity">
		
			<>
				
				{
					
					menuMobile ? 
					
					(
					
						<HeaderMobile />
						
					) 
					
					: 
					
					(

						<Header 
							search 
							route={` Administração /  ${entity?.nome ? entity?.nome : 'Não encontrado'}`} 
						/>
						
					)
					
				}
				
				{
					
					mobile && 
					
					<SearchMobile />
					
				}
				
				{
					
					mobile && 
					
					<div className="pages-Entity-mobile-separador" />
					
				}
				
				<TitlePage title={entity?.nome ? entity?.nome : 'Sem Nome'} />
				
				<div className="pages-Entity-container">
					
					{
					
						!mobile && 
					
						<div className="pages-Entity-esq">
						
							<div className="pages-Entity-item"></div>
							
							{
								
								listMenu.length > 0 && (
								
									<FlatList
										list={listMenu}
										renderItem={(item) => (
										
											<ItemCategorie 
												key={item.cod_menu} 
												idActual={parseInt(id || '0')} 
												newsCategorie={item} 
											/>
											
										)}
									/>
									
								)
								
							}
							
						</div>
						
					}
					
					<div className="pages-Entity-dir">
					
						<div 
							className="pages-Entity-conteudo" 
							dangerouslySetInnerHTML={{ __html: content || '' }} 
						/>
						
						<div className="pages-Entity-container-full">
						
							{
								
								entity?.nome_gestor &&
								
								<div className="pages-Entity-linha">
								
									<SubTitlePage title={entity?.titulo_gestor} />
									
									<div className="pages-Entity-linha-txt">{entity?.nome_gestor}</div>
									
								</div>
								
							}
						
						</div>
						
						<div className="pages-Entity-container-full">
							
							{
								
								entity?.horario_atendimento &&
								
								<div className="pages-Entity-linha">
								
									<SubTitlePage title="Horário de Atendimento" />

									<div className="pages-Entity-linha-txt">{entity?.horario_atendimento}</div>
									
								</div>
								
							}
							
						</div>

						<div className="pages-Entity-conteudo-txt pages-Entity-mobile100">
						
							{
								
								entity?.email &&
								
								<div className="pages-Entity-email">

									<SubTitlePage title="Email" />

									<div className="pages-Entity-linha-txt">{entity?.email}</div>
									
								</div>
								
							}
							
							{
								
								entity?.telefones &&
								
								<div className="pages-Entity-telefone pages-Entity-mobile100">
								
									<SubTitlePage title="Telefone" />

									<div className="pages-Entity-linha-txt">{entity?.telefones}</div>
									
								</div>
								
							}
							
						</div>
						
						<div className="pages-Entity-endereco-container">
						
							{
								
								entity?.endereco &&
								
								<div className="pages-Entity-endereco">
								
									<SubTitlePage title="Endereço" />
									
									<div className="pages-Entity-linha-txt">{entity?.endereco} { entity?.numero && `,${entity?.numero}` }</div>
									
									{ entity?.bairro && <div className="pages-Entity-linha-txt">{entity?.bairro}</div> }
									{ entity?.cidade && <div className="pages-Entity-linha-txt">{entity?.cidade} (SC)</div> }
									
								</div>
								
							}
							
						</div>
						
					</div>
					
				</div>

				<Banner mobile={mobile} />
				
				<div className="pages-Entity-separador"></div>
				
				<MapSite />
				
				<Footer />
				
				{
					
					visible && 
					
					(
					
						<VLibras forceOnload />
						
					)
					
				}
				
			</>
			
		</div>
		
	)
	
}