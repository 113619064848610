import { useEffect, useState } from 'react'
import video1 from '../../assets/imgs/video1.jpeg'
import video2 from '../../assets/imgs/video2.jpeg'
import { api, urls } from '../../services/api'
import { IVideo } from '../../interfaces/video'
import { useApp } from '../../context/app'
import { TitlePage } from '../TitlePage'
import './style.css'

interface Props {
	mobile: boolean
}

export function Videos({ mobile }: Props) {
	
	const [videos, setVideos] = useState<IVideo[]>([])

	useEffect(() => {
		(async () => {
			
			const response = await api.get(urls.videosDestaque)
			
			//console.log('Videos ln 23 - response.data:', response.data)
			
			setVideos(response.data)
			
		})()
	}, [])

	if (videos.length < 1) {
		
		return (
			<></>
		)
		
	}

	return (
		
		<>
		
			{
				
				!!videos[0] &&
				!!videos[1] &&
				
				<div className="Videos">
				
					<div className="Videos-container">
					
						<div className="Videos-box">
							
							{
								
								!mobile &&
							
								<div className="Videos-titulo">
									<span className="Videos-bar"/>Vídeos<span className="Videos-bar"/>
								</div>
								
							}
							
							{
						
								mobile &&
								
								<div className="AcessoRapido-titulo-box">
									<TitlePage title="VÍDEOS" />
								</div>
								
							}
							
							<div className="Videos-itens">
							
								<div className="Videos-item">
								
									<iframe 
										src={`https://www.youtube.com/embed/${videos[0].ytId}`}
									></iframe>
									<a 
										href={`https://www.youtube.com/watch?v=${videos[0].ytId}`}
										target='_blank'
									>{videos[0].title}</a>
									
								</div>
								
								<div className="Videos-item">
								
									<iframe 
										src={`https://www.youtube.com/embed/${videos[1].ytId}`}
									></iframe>
									<a 
										href={`https://www.youtube.com/watch?v=${videos[1].ytId}`}
										target='_blank'
									>{videos[1].title}</a>
									
								</div>
								
							</div>
							
							<div className="Videos-btn"><div className="Videos-btn-lente"></div><a href="/videos">Mais Vídeos</a></div>
							
						</div>
						
					</div>
					
				</div>
				
			}
			
		</>
		
	)
	
}