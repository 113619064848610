import logo from '../../assets/imgs/logo.png'
import { HomeIcon } from '../icons/common/home'
import { api, urls } from '../../services/api';
import { useEffect, useRef, useState } from 'react';
import { IMenuItem } from '../../interfaces/menu';
import { ItemMenuNav } from './components/ItemMenuNav';
import './style.css'
import { useApp } from '../../context/app';

interface Props {
	search?: boolean
	dataSearch?: any[]
	onChange?: (array: any[]) => void
	route?: string
}

export function HeaderMobile({ search = false, route }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [menu, setMenu] = useState<IMenuItem[]>([])
	const [idMenu, setIdMenu] = useState<number>(0)
	const ref = useRef<HTMLDivElement>(null)

	const [flag, setFlag] = useState<boolean>(false);
	const toggleFlag = () => setFlag(value => !value);

	useEffect(() => {
		(async () => {

			const response = await api.get(urls.menus)

			setMenu(response.data)

		})()
	}, [])

	const renderMenu = (item: IMenuItem[]) => {

		return item.map((itemMenu: IMenuItem, index) => {

			return (

				<ItemMenuNav
					key={index}
					idActual={idMenu}
					itemMenu={itemMenu}
					onChange={setIdMenu}
				/>

			)

		})

	}

	return (

		<>

			<div className="HeaderMobile">

				<div className="HeaderMobile-container">

					<a href={domain}>
						<img
							src={logo}
							className="HeaderMobile-logo"
						/>
					</a>

					<span
						ref={ref}
						className="HeaderMobile-hamburger"
						onClick={toggleFlag}
					>&#9776;</span>

				</div>

			</div>

			{

				route &&

				<div className="HeaderMobile-breadcrumb-container">

					<div className="HeaderMobile-breadcrumb">Inicial /{route}</div>
					
				</div>
				
			}

			<div
				className={`HeaderMobile-sidenav-escurecer ${flag === false ? "" : "on"}`}
			></div>

			<div
				id="mySidenav"
				ref={ref}
				className={`HeaderMobile-sidenav ${flag === false ? "" : "on"}`}
			>

				<a
					href="#"
					className="HeaderMobile-sidenav-closebtn"
					onClick={toggleFlag}
				>&times;</a>

				<a
					//onClick={handleOpenModal}
					//onMouseEnter={handleOpenModal}
					className="HeaderMobile-sidenav-inicio"
				>{<HomeIcon />} Início</a>

				{renderMenu(menu)}

			</div>

		</>

	)

}