import { useState } from 'react'
import { api, urls } from '../../services/api'
import { SearchIcon } from '../icons/common/search'
import { Navigate } from 'react-router-dom'
import './style.css'
import { useApp } from '../../context/app'

export function Search() {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [inputSearch, setInputSearch] = useState<string>('')

	return (

		<div className="Search">

			<div className="Search-container">

				<div className="Search-lente"></div>

				<div className="Search-container-box">

					<form
						action={`${domain}busca/${inputSearch}`}
						className="Search-form"
					>

						<input
							type='text'
							onChange={e => setInputSearch(e.target.value)}
							placeholder='Procure no portal'
						/>

						<button type='submit'>
							<SearchIcon />
						</button>

					</form>

					<div className="Search-botoes">

						<button
							onClick={() => {
								window.open(
									"https://itajai.aprova.com.br/",
									"_blank"
								)
							}}
						>Aprova Digital</button>

						<button
							onClick={() => {
								window.open(
									"https://servicos.itajai.sc.gov.br/servico/54-nfse.html",
									"_blank"
								)
							}}
						>NFS-e</button>

						<button
							onClick={() => {
								window.open(
									"https://servicos.itajai.sc.gov.br/servico/76-jornal_do_municipio_de_itajai.html",
									"_blank"
								)
							}}
						>Jornais</button>

						<button
							onClick={() => {
								window.open(
									"https://webmail.itajai.sc.gov.br/",
									"_blank"
								)
							}}
						>Webmail</button>

					</div>

				</div>

			</div>

		</div>

	)

}