import { useApp } from "../../../../context/app";
import { INews } from "../../../../interfaces/news";
import { parseDate } from "../../../../utils/date";

interface Props {
	news: INews
}
export function BottomNewsMobile({ news }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL

	const link_montado = news.title
		.toLowerCase()
		.replaceAll(' ', '-')
		.replaceAll('/', '-')
		.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, "")

	//console.log('BottomNewsMobile.tsx ln 21 - link_montado')
	//console.log(link_montado)

	return (

		<a
			className="BottomNewsMobile"
			href={`${domain}noticias/${news.newsId}/${link_montado}`}
		>

			<div className="BottomNewsMobile-campo">
				<div className="BottomNewsMobile-titulo">{news.title}</div>
			</div>

			<div className="BottomNewsMobile-data">{parseDate(news.published)}</div>

		</a>

	)

}