interface Props {
    children: React.ReactNode
}

export function Subontainer({ children }: Props) {
	
    return (
	
        <div className="SubContainer">
            {children}
        </div>
		
    )
	
}