export function Title() {
	
    return (
	
        <div className="pages-Contact-Title">
            <div className="pages-Contact-Title70">SETOR</div>
            <div className="pages-Contact-Title30">TELEFONE</div>
        </div>
		
    )
	
}