import React from "react"

interface Props {
    children: React.ReactNode
}
export function ListBottomNews({ children }: Props) {
	
    return (
	
        <div className='ListBottomNews'>
			
            {children}
			
        </div>
		
    )
}