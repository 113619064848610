export function GridIcon() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8333 3.25H3.25V10.8333H10.8333V3.25Z" stroke="#636363" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.7498 3.25H15.1665V10.8333H22.7498V3.25Z" stroke="#636363" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.7498 15.1667H15.1665V22.75H22.7498V15.1667Z" stroke="#636363" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8333 15.1667H3.25V22.75H10.8333V15.1667Z" stroke="#636363" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}