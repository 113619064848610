interface Props {
    onClick: () => void
    title: string
    id: number
    idActual?: number
}
export function MenuItem({ onClick, id, idActual, title }: Props) {
    return (
        <a 
			className={ id == idActual ? 'Services-btn active' : 'Services-btn' }
            onClick={onClick}
        >
            {title}
        </a>
    )
}