interface Props {
    children: React.ReactNode
}

export function ListSideNews({ children }: Props) {
	
    return (
	
        <div className="ListSideNews">
		
            {children}
			
        </div>
		
    )
	
}