interface Props {
    children: React.ReactNode
    href: string
}
export function ButtonMobile({ children, href }: Props) {
    return (
        <a
            href={href}
			className="SearchMobile-ButtonMobile"
        >{children}</a>
    )
}