import { ICategorieNews } from "../../../interfaces/news"

interface Props {
    newsCategorie: ICategorieNews
    idActual: number
    onClick: () => void
}
export function ItemCategorie({ idActual, newsCategorie, onClick }: Props) {
	
    return (
	
        <div 
			className={
				
				newsCategorie.newsCategoryId == idActual ? 
				
				"page-ListNews-ItemCategorie ativo" 
				
				: 
				
				"page-ListNews-ItemCategorie"
				
			}
            onClick={onClick}
        >{newsCategorie.title}</div>
		
    )
	
}