import { SubTitlePage } from "../SubTitlePage";
import { useApp } from "../../context/app";
import { Fechar } from '../icons/common/fechar'
import { DownloadIcon } from '../icons/common/download'
import './style.css';

interface Props {
	files: string[]
}

export function ListFiles({ files }: Props) {
	
	//console.log('files', files)
	
	return (
		<div className="ListFiles">

			<SubTitlePage title="Arquivos Relacionados" />
			
			<div className="ListFiles-itens">
				{
					files.map((item:any, index) => {
						
						return(
							<div className="ListFiles-item">
								<a 
									href={item.url} 
									target="_blank" 
								>
									<div className="ListFiles-icone"><DownloadIcon /></div>
									<div className="ListFiles-nome"><span>{item.titulo}</span></div>
								</a>
							</div>
						)
						
					})
					
				}
			</div> 
			
		</div>
		
	)
	
}