import { useApp } from "../../context/app"
import "./style.css"

interface Props {
    title: string
}

export function SubTitlePage({ title }: Props) {
	
    const { mobile } = useApp()
	
    return (
	
        <div className="SubTitlePage">
		
            <div className="SubTitlePage-titulo">{title}</div>
			
            <div className="SubTitlePage-campo">
                <div className="SubTitlePage-amarelo"/>
                <div className="SubTitlePage-bar"/>
            </div>
			
        </div>
		
    )
	
}