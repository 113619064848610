import { useEffect, useState } from "react";
import { SubTitlePage } from "../SubTitlePage";
import { INews } from "../../interfaces/news";
import { api, urls } from "../../services/api";
import Icon from "@mdi/react";
import { mdiImageOff } from "@mdi/js";
import { useApp } from "../../context/app";
import brasao from '../../assets/imgs/brasao.png'
import "./style.css"

interface Props {
	idNews: string
	categorieNewsId: number
}

export function OtherNews({ idNews, categorieNewsId }: Props) {

	const [news, setNews] = useState<INews[]>([])
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [layoutGrid, setLayoutGrid] = useState<boolean>(false)

	function parseDate(date: string) {

		const dateSplit = date.split('/')
		const monthsOfYear = [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro'
		]
		const month = monthsOfYear[parseInt(dateSplit[1]) - 1].toUpperCase()
		const dateParsed = `${dateSplit[0]} ${month} ${2024}`
		return dateParsed

	}

	const OtherNews_img_caminho = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/'

	useEffect(() => {
		(async () => {

			try {

				const response = await api.get(urls.NewsByCategorie + categorieNewsId + '/cod_noticia_atual/' + idNews)
				setNews(response.data.noticias.slice(0, 4))

			} catch (error) {

				console.log('src/components/OtherNews ln 55 - response erro: ', error)

			}

		})()
	}, [categorieNewsId])

	//console.log('news: ', news)

	return (

		<>

			{

				news.length > 0 &&

				<div className="OtherNews">

					<SubTitlePage title="Noticias Relacionadas" />

					<div className="OtherNews-container">

						{

							news.map(item => (

								<a
									href={`${domain}noticias/${item.newsId}/${item.title.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-').replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`}
									className="OtherNews-item"
								>

									{

										item.img ?

											<img src={OtherNews_img_caminho + item.img} />

											:

											<div
												className="OtherNews-icone empty"
												style={{
													backgroundImage: 'url( ' + brasao + ' )',
												}}
											></div>

									}

									<div className="OtherNews-col">
										<div className="OtherNews-titulo"><span>{item.title}</span></div>
										<div className="OtherNews-data">{parseDate(item.published)}</div>
									</div>

								</a>

							))

						}

					</div>

				</div>

			}

		</>

	)

}