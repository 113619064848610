type TitleProps = {
    children: string
}
export function TitleInfo({ children }: TitleProps) {
	
    return (
	
        <div className="MapSite-TitleInfo">{children}</div>
		
    )
	
}