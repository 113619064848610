import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import logo from '../../assets/imgs/logo.png'
import { HomeIcon } from '../icons/common/home'
import { api, urls } from '../../services/api';
import { useEffect, useState } from 'react';
import { IMenuItem } from '../../interfaces/menu';
import { ItemMenuNav } from './components/ItemMenuNav';
import './style.css';
import { useApp } from '../../context/app';

interface Props {
	search?: boolean
	dataSearch?: any[]
	onChange?: (array: any[]) => void
	route?: string
}

export function Header({ search = false, route }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [menu, setMenu] = useState<IMenuItem[]>([])
	const [idMenu, setIdMenu] = useState<number>(0)
	const [inputSearch, setInputSearch] = useState<string>('')

	useEffect(() => {
		(async () => {

			const response = await api.get(urls.menus)

			//console.log('Header ln 29 - response.data:', response.data)

			setMenu(response.data)

		})()
	}, [])

	//console.log('Header ln 37 - search: ', search)

	return (
		<>

			<div className={!search ? ("Header") : ("Header Header-pagina-interna")}>

				<div className="Header-itens">

					<a href={domain}>
						<img src={logo} />
					</a>

					<div className="Header-button-box">

						{
							search && (

								<form
									className="Header-search-form"
									action={`${domain}busca/${inputSearch}`}
								>

									<input
										className="Header-search-form-input"
										placeholder='Buscar'
										onChange={e => setInputSearch(e.target.value)}
									/>

									<button
										className="Header-search-form-button"
										type='submit'
									>
										<Icon
											path={mdiMagnify}
											color={'white'}
										/>
									</button>

								</form>

							)
						}

						<div
							className="Header-navBarHover"
							id="navBarHover"
						>

							<div className="Header-btn">
								<a href={domain}>
									<HomeIcon />
									Início
								</a>
							</div>

							{

								menu && menu.map((item, index) => (

									<ItemMenuNav
										key={index}
										idActual={idMenu}
										itemMenu={item}
										onChange={setIdMenu}
										count={index + 1}
									/>

								))

							}

						</div>

					</div>

				</div>

			</div>

			{
				route &&

				<div className="Header-breadcrumb">

					<div className="Header-breadcrumb-item">

						Inicial /{route}

					</div>

				</div>

			}


		</>

	)

}