import { useApp } from "../../context/app";
import { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Header } from "../../components/Header";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { Banner } from "../../components/Banner";
import { MapSite } from "../../components/MapSite";
import { Footer } from "../../components/Footer";
import { TitlePage } from "../../components/TitlePage";
import { api, urls } from '../../services/api';
import { IVideo } from '../../interfaces/video';
import video1 from '../../assets/imgs/video1.jpeg';
import VLibras from '@djpfs/react-vlibras';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function ListVideosScreen() {
	
	const { mobile, menuMobile, visible } = useApp()
	const [videos, setVideos] = useState<IVideo[]>([])
	
	useEffect(() => {
		(async () => {
			
			const response = await api.get(urls.VideosObject)
			
			//console.log('response.data:', response.data['videos'])
			
			setVideos(response.data['videos'])
			
		})()
	}, [])
	
	//console.log( 'videos', videos )

	return (
		<div className="pages-ListVideos">
			
			<>
				
				{
					
					menuMobile ? 
					
					(
					
						<HeaderMobile />
						
					) 
					
					: 
					
					(

						<Header 
							search 
							route={` Notícias`}
						/>
						
					)
					
				}
				
				{
					
					mobile && 
					
					<SearchMobile />
					
				}
				
				<TitlePage title="VÍDEOS" />
				
				<div className="pages-ListVideos-container">
					
					<FlatList
						list={videos.slice(0,1)}
						renderItem={(item) => (
						
							<div className="pages-ListVideos-destaque">
							
								<div className="pages-ListVideos-destaque-video">
									<iframe src={`https://www.youtube.com/embed/${item.ytId}`} />
								</div>
								<a 
									href={`https://www.youtube.com/watch?v=${item.ytId}`}
									target="_blank"
								><div className="pages-ListVideos-destaque-titulo">{item.title}</div></a>
								
							</div>
							
						)}
					/>
					
					<div className="pages-ListVideos-listagem">
						
						<FlatList
							list={videos.slice(1,videos.length)}
							renderItem={(item) => (
							
								<div className="pages-ListVideos-item">
									<a 
										href={`https://www.youtube.com/watch?v=${item.ytId}`}
										target="_blank"
									>
										<div 
											className="pages-ListVideos-item-capa"
											style={{
												backgroundImage:`url(//img.youtube.com/vi/${item.ytId}/0.jpg)`
											}}
										></div>
										<div className="pages-ListVideos-item-titulo">{item.title}</div>
									</a>
								</div>
								
							)}
						/>
						
					</div>
					
				</div>
				
				<Banner mobile={mobile} />
				
				<MapSite />
				
				<Footer />
				
				{
					
					visible && 
					
					(
					
						<VLibras forceOnload />
						
					)
					
				}
				
			</>
			
		</div>
		
	)

}