import { useApp } from "../../../../context/app";
import { INews } from "../../../../interfaces/news";
import { parseDate } from "../../../../utils/date";

interface Props {
	news: INews
}

export function MainNews({ news }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const link_montado = news.title
		.toLowerCase()
		.replaceAll(' ', '-')
		.replaceAll('/', '-')
		.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, "")

	const MainNews_img = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/' + news.img

	//console.log('MainNews.tsx ln 18 - link_montado')
	//console.log(link_montado)

	return (

		<a
			href={`${domain}noticias/${news.newsId}/${link_montado}`}
			className="MainNews"
		>

			{

				!!news.img &&

				<img
					src={MainNews_img}
					className="MainNews-img"
				/>

			}

			<div className="MainNews-data">

				{parseDate(news.published)}

			</div>

			<div className="MainNews-titulo">

				{news.title}

			</div>

		</a>

	)

}