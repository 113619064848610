import { IServico } from "../../../interfaces/listItems";

export function Item({ cod_servico, descricao, link_servico, titulo, url }: IServico) {
    return (
        <li>
            <a 
                href={`https://jorge.itajai.sc.gov.br/portaladm-pmitajai/servico/acesso/cod/${cod_servico}`} 
                target="_blank" 
				className="Services-conteudo-item"
            >• {titulo}</a>
        </li>
    )
} 