import { useEffect, useState } from "react"
import { api, urls } from "../../services/api"
import { Item } from "./components/Item"
import { IMenu } from "../../interfaces/listItems"
import { MenuItem } from "./components/menuItem"
import "./styles.css"

export function Services() {
	
	const [listMenu, setListMenu] = useState<IMenu[]>([])
	const [menuSelected, setMenuSelected] = useState<IMenu>()
	
	useEffect(() => {
		(async () => {
			
			const response = await api.get(urls.Services)
			setListMenu(response.data)
			setMenuSelected(response.data[0])
			
		})()
	}, [])
	
	return (
	
		<div className="Services">
		
			<div className="Services-container">
			
				<div className="Services-topo">
				
					<div className="Services-btn-titulo">Serviços</div>
					
					{
						listMenu.map((item, index) => (
							<MenuItem 
								key={index} 
								idActual={menuSelected?.id || 0} 
								id={item.id} 
								title={item.menu} 
								onClick={() => setMenuSelected(item)} 
							/>
						))
					}
					
				</div>
				
				<div className="Services-conteudo">
				
					<ul className="item-container">
						{
							!!menuSelected && menuSelected.servicos.map((service) => (
								<Item 
									titulo={service.titulo} 
									cod_servico={service.cod_servico} 
								/>
							))
						}
					</ul>
					
				</div>
				
				<div className="Services-bottom">
				
					<a 
						href="https://servicos.itajai.sc.gov.br/" 
						target="_blank"
					>Veja todos os serviços</a>
					
				</div>
				
			</div>
			
		</div>
		
	)
	
}