import { useEffect, useState } from "react"
import { api, urls } from "../../services/api"
import { INews } from "../../interfaces/news"
import { SideTopNews } from "./components/TopNews/SideNews"
import { BottomNews } from "./components/TopNews/BottomNews"
import { ListBottomNews } from "./components/ListTopNews/ListBottomNews"
import { ListSideNews } from "./components/ListTopNews/ListSideNews"
import { MainNews } from "./components/TopNews/MainNews"
import { Container } from "./components/Containers/Container"
import { Subontainer } from "./components/Containers/Subcontainer"
import { useApp } from "../../context/app"
import { TitlePage } from "../TitlePage"
import { CardMobileNews } from "./components/TopNews/CardMobileNews"
import { BottomNewsMobile } from "./components/TopNews/BottomNewsMobile"
import "./style.css"

export function News() {
	
    const [news, setNews] = useState<INews[]>([])
    const [getPeriodoEleitoral, setPeriodoEleitoral] = useState()
    const { mobile } = useApp()

    useEffect(() => {
        (async () => {
            try {
				
                const response = await api.get(urls.ListNews)
				//console.log( 'response.data', response.data )
				
                setNews(response.data.noticias)
                setPeriodoEleitoral(response.data.periodoEleitoral)
				
            } catch (error) {
                console.log('src/components/News ln 36 Erro: ', error)
            }
        })()
    }, [])

    if (
		news.length < 5
		&& getPeriodoEleitoral == 'Não'
	) {
        return (
            <div></div>
        )
    }
	
	//console.log( 'news', news )
	//console.log( 'getPeriodoEleitoral', getPeriodoEleitoral )
    
	if ( mobile ) {
		
        return (
			
			<>
				
				{
					
					news.length > 0 &&
					
					<div className="News-Mobile">
						
						{
							
							getPeriodoEleitoral == 'Não' &&
							
							<>
								
								<div className="News-Mobile-linha01">
									
									{ news[0] && <CardMobileNews news={news[0]} />}
									{ news[1] && <CardMobileNews news={news[1]} />}
									
								</div>
								
								<div className="News-Mobile-linha02" >
									{ news[2] && <CardMobileNews news={news[2]} />}
									{ news[3] && <CardMobileNews news={news[3]} />}
								</div>
								
							</>
							
						}
						
						<TitlePage title="ÚLTIMAS NOTÍCIAS" />
						
						<Container>
							
							{ news[0] && getPeriodoEleitoral == 'Sim' && <BottomNewsMobile news={news[0]} /> }
							{ news[1] && getPeriodoEleitoral == 'Sim' && <BottomNewsMobile news={news[1]} /> }
							{ news[2] && getPeriodoEleitoral == 'Sim' && <BottomNewsMobile news={news[2]} /> }
						
							{ news[3] && <BottomNewsMobile news={news[3]} />}
							{ news[4] && <BottomNewsMobile news={news[4]} />}
							{ news[5] && <BottomNewsMobile news={news[5]} />}
							{ news[6] && <BottomNewsMobile news={news[6]} />}
						
							{ news[7] && <BottomNewsMobile news={news[7]} />}
							{ news[8] && <BottomNewsMobile news={news[8]} />}
							{ news[9] && <BottomNewsMobile news={news[9]} />}
							{ news[10] && <BottomNewsMobile news={news[10]} />}
							
						</Container>
						
						<div className="News-btn"><a href="/noticias">Mais Notícias</a></div>
						
					</div>
					
				}
				
			</>
			
        )
		
	}
    
	return (
	
        <Container>
			
			{
				
				news.length > 0 &&
				
				<>

					{
						
						getPeriodoEleitoral == 'Não' &&
						
						<>
						
							<Subontainer>
							
								{ news[0] && <MainNews news={news[0]} />}
								
								<ListSideNews>
								
									{ news[1] && <SideTopNews news={news[1]} />}
									{ news[2] && <SideTopNews news={news[2]} />}
									
								</ListSideNews>
								
							</Subontainer>
							
						</>
						
					}

					<div className="MaisNoticias">
						<div className="MaisNoticias-container">
							<span className="MaisNoticias-bar"/>
							<span className="MaisNoticias-titulo">Últimas Notícias</span>
							<span className="MaisNoticias-bar"/>
						</div>
					</div>
					
					<ListBottomNews>
					
						{ news[0] && getPeriodoEleitoral == 'Sim' && <BottomNews news={news[0]} /> }
						{ news[1] && getPeriodoEleitoral == 'Sim' && <BottomNews news={news[1]} /> }
						{ news[2] && getPeriodoEleitoral == 'Sim' && <BottomNews news={news[2]} /> }
						
						{ news[3] && <BottomNews news={news[3]} />}
						{ news[4] && <BottomNews news={news[4]} />}
						{ news[5] && <BottomNews news={news[5]} />}
						{ news[6] && <BottomNews news={news[6]} />}
						
						{ news[7] && <BottomNews news={news[7]} />}
						{ news[8] && <BottomNews news={news[8]} />}
						{ news[9] && <BottomNews news={news[9]} />}
						{ news[10] && <BottomNews news={news[10]} />}
						
					</ListBottomNews>
					
					<div className="News-btn"><div className="News-btn-lente"></div><a href="/noticias">Mais Notícias</a></div>
					
				</>
				
			}
			
        </Container>
		
    )
	
}