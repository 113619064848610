import { useApp } from "../../../context/app"
import { ISection } from "../../../interfaces/menu"

interface Props {
	section: ISection
}
export function SubItemMenuNav({ section }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	return (
		<div className="SubItemMenuNav">

			<div className="SubItemMenuNav-titulo">{section.titulo}</div>

			<ul>
				{
					section.menus?.map((item) => {

						//console.log('Header-subMenuNav ln 24 - item:')
						//console.log(item)

						let subMenuNav_link = '#'

						if (
							item.link
							&& item.ref_cod_menu_tipo_componente == 13
						) { subMenuNav_link = item.link }

						if (
							item.ref_cod_artigo
							&& (
								item.ref_cod_menu_tipo_componente == 2
								|| item.ref_cod_menu_tipo_componente == 12
								|| item.ref_cod_menu_tipo_componente == 14
							)
						) { subMenuNav_link = domain + 'e/' + item.ref_cod_artigo }

						if (
							item.ref_cod_entidade
							&& (
								item.ref_cod_menu_tipo_componente == 15
								|| item.ref_cod_menu_tipo_componente == 19
							)
						) { subMenuNav_link = domain + 'a/' + item.ref_cod_entidade }

						//console.log('Header-subMenuNav ln 52 - subMenuNav_link:')
						//console.log(subMenuNav_link)

						let subMenuNav_target = '_self'
						if (item.nova_janela == true) { subMenuNav_target = '_blank' }

						return (
							<li>
								<a
									//href={`${domain}${!!item.ref_cod_artigo ? 'e/' + item.ref_cod_artigo : 'a/' + item.ref_cod_entidade}`} 
									href={`${subMenuNav_link}`}
									target={`${subMenuNav_target}`}
									className="hover-submenu-item-itemMenuNav"
								>
									• {item.titulo}
								</a>
							</li>
						)

					})

				}

			</ul>

		</div>

	)

}