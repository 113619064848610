import { useEffect, useState } from "react"
import { api, urls } from "../../services/api"
import { Item } from "./components/Item"
import { IMenu } from "../../interfaces/listItems"
import { MenuItem } from "./components/menuItem"
import Accordion from 'react-bootstrap/Accordion';
import "./styles.css"

export function ServicesAccordion() {
	
	const [listMenu, setListMenu] = useState<IMenu[]>([])
	const [menuSelected, setMenuSelected] = useState<IMenu>()
	
	useEffect(() => {
		(async () => {
			
			const response = await api.get(urls.Services)
			
			setListMenu(response.data)
			setMenuSelected(response.data[0])
			
		})()
	}, [])
	
	return (
	
		<div className="ServicesAccordion">
		
			<div className="ServicesAccordion-container">

				<div className="ServicesAccordion-campo">
				
					<div className="ServicesAccordion-titulo">Serviços</div>
					
				</div>
				
				<Accordion defaultActiveKey="0">
				
					{
						
						listMenu.map((item, index) =>
						
							<Accordion.Item 
								key={index} 
								eventKey={`${item.id}`}
							>
							
								<Accordion.Header>{item.menu}</Accordion.Header>
								
								<Accordion.Body className="ServicesAccordion-body">
								
									<ul>
									
										{
											
											item.servicos.map((service, index) => (
											
												<Item 
													key={index} 
													titulo={service.titulo} 
													cod_servico={service.cod_servico} 
												/>
												
											))
											
										}
										
									</ul>
									
								</Accordion.Body>
								
							</Accordion.Item>
							
						)
						
					}
					
				</Accordion>
				
				<div className="ServicesAccordion-bottom">
				
					<a 
						href="https://servicos.itajai.sc.gov.br/" 
						className="ServicesAccordion-bottom-link"
					>Veja todos os serviços</a>
					
				</div>
				
			</div>
			
		</div>
		
	)
	
}