import { useApp } from "../../../../context/app"

interface Props {
	children: React.ReactNode
}

export function Container({ children }: Props) {
	
	const { mobile } = useApp()
	
	return (
	
		<div className="News">
		
			{children}
			
		</div>
		
	)
	
}