import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { useEffect, useState } from "react";
import { api, urls } from "../../services/api";
import { INewsComplet } from "../../interfaces/news";
import { SubTitlePage } from "../../components/SubTitlePage";
import { ListImages } from "../../components/ListImages";
import { MapSite } from "../../components/MapSite";
import { OtherNews } from "../../components/OtherNews";
import { ItemCategorie } from "./components/itemCategorie";
import FlatList from "flatlist-react/lib";
import { IArticle } from "../../interfaces/article";
import { IMenuItem, IMenu } from "../../interfaces/menu";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { Footer } from "../../components/Footer";
import { Banner } from "../../components/Banner";
import VLibras from '@djpfs/react-vlibras';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function LocationScreen() {
	
	const { mobile, menuMobile, visible } = useApp()

	return (
		<div className="pages-Location">
			
			<>
				
				{
					
					menuMobile ? 
					
					(
					
						<HeaderMobile />
						
					) 
					
					: 
					
					(

						<Header 
							search 
							route={` Localização`}
						/>
						
					)
					
				}
				
				{
					
					mobile && 
					
					<SearchMobile />
					
				}

				<TitlePage title={'Localização'} />
				
				<div className="pages-Location-container">

					<iframe 
						className="pages-Location-iframe"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.972731874244!2d-48.67072753428655!3d-26.904360491625418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8cc65fb9adb4f%3A0x2ac8437a8cc5887c!2sPrefeitura%20Municipal%20de%20Itaja%C3%AD!5e0!3m2!1spt-BR!2sbr!4v1708628368508!5m2!1spt-BR!2sbr" 
						loading="lazy" 
					></iframe>

					<div className="pages-Location-dir">
						
						<div className="pages-Location-titulo">Endereço:</div>
						<div className="pages-Location-txt">
							Rua Alberto Werner, 100<br/>
							Bairro: Vila Operária<br/>
							Itajaí (SC)<br/>
							CEP: 88304-900
						</div>
						
						<div className="pages-Location-titulo">Telefone:</div>
						<div className="pages-Location-txt">(47) 33416000</div>
						
						<div className="pages-Location-titulo">Atendimento:</div>
						<div className="pages-Location-txt">
							08h às 12h e 14h às 18h de<br/>
							segunda a sexta-feira
						</div>
						
					</div>
					
				</div>
				
				<Banner mobile={mobile} />
				
				<div className="pages-Location-separador"></div>
				
				<MapSite />
				
				<Footer />
				
				{
					
					visible && 
					
					(
					
						<VLibras forceOnload />
						
					)
					
				}
				
			</>
			
		</div>
		
	)
	
}