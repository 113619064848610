import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { MapSite } from "../../components/MapSite";
import { Footer } from "../../components/Footer";
import { ListContact } from "./components/ListContact/listContact";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { ListContactMobile } from "./components/ListContactMobile/listContact";
import VLibras from '@djpfs/react-vlibras';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function ContactScreen() {
	
	const { mobile, menuMobile, visible } = useApp()
	
	const contactList = [
		{
			"name": "Controladoria Geral do Município",
			"phone": "(47) 3341-6140"
		},
		{
			"name": "Coord. Geral de Contabilidade",
			"phone": "(47) 3341-6251"
		},
		{
			"name": "Coord. Regional de Atendimento ao Cidadão da Fazenda/ Praia Brava",
			"phone": "(47) 3346-3830"
		},
		{
			"name": "Coord. Regional de Atendimento ao Cidadão de Cordeiros",
			"phone": "(47) 3346-2050"
		},
		{
			"name": "Coord. Regional de Atendimento ao Cidadão de São Vicente",
			"phone": "(47) 3241-2188"
		},
		{
			"name": "Coord. Regional de Atendimento ao Cidadão do Santa Regina",
			"phone": "(47) 3247-2130"
		},
		{
			"name": "Coordenadoria de Defesa Civil",
			"phone": "(47) 3341-6199"
		},
		{
			"name": "Fundação Cultural",
			"phone": "(47) 3348-3610, (47) 3349-1516"
		},
		{
			"name": "Fundação de Educação Profissional e Administração Pública",
			"phone": "(47) 3390-4000"
		},
		{
			"name": "Fundação de Esporte e Lazer",
			"phone": "(47) 3348-7478, (47) 3348-1473"
		},
		{
			"name": "Fundação Genésio Miranda Lins",
			"phone": "(47) 3349-7573"
		},
		{
			"name": "Gabinete do Prefeito",
			"phone": "(47) 3341-6001"
		},
		{
			"name": "Gabinete do Vice-Prefeito",
			"phone": "(47) 3341-6003"
		},
		{
			"name": "Instituto Cidade Sustentável",
			"phone": "(47) 3348-8031"
		},
		{
			"name": "Instituto de Previdência",
			"phone": "(47) 3405-6000"
		},
		{
			"name": "Itajaí Participações",
			"phone": "(47) 3349-4721"
		},
		{
			"name": "Planejamento Estratégico do Município de Itajaí",
			"phone": "(47) 3341-6067"
		},
		{
			"name": "Prefeitura",
			"phone": "(47) 3341-6000"
		},
		{
			"name": "Procuradoria Geral do Município",
			"phone": "(47) 3341-6042"
		},
		{
			"name": "Secretaria da Fazenda",
			"phone": "(47) 3341-6200"
		},
		{
			"name": "Secretaria de Administração e Gestão de Pessoas",
			"phone": "(47) 3341-6106"
		},
		{
			"name": "Secretaria de Agricultura e Expansão Urbana",
			"phone": "(47) 3346-5500"
		},
		{
			"name": "Secretaria de Assistência Social",
			"phone": "(47) 3248-0800"
		},
		{
			"name": "Secretaria de Comunicação",
			"phone": "(47) 3341-6049"
		},
		{
			"name": "Secretaria de Desenvolvimento Econômico",
			"phone": "(47) 3246-1190, (47) 3344-2308"
		},
		{
			"name": "Secretaria de Desenvolvimento Urbano e Habitação",
			"phone": "(47) 3341-6076, (47) 3908-5626"
		},
		{
			"name": "Secretaria de Educação",
			"phone": "(47) 3249-3300"
		},
		{
			"name": "Secretaria de Governo",
			"phone": "(47) 3341-6252"
		},
		{
			"name": "Secretaria de Obras",
			"phone": "(47) 3348-0303, (47) 3348-0202"
		},
		{
			"name": "Secretaria de Promoção da Cidadania",
			"phone": "(47) 3349-4297"
		},
		{
			"name": "Secretaria de Saúde",
			"phone": "(47) 3249-5500"
		},
		{
			"name": "Secretaria de Segurança Pública",
			"phone": "(47) 3249-5800"
		},
		{
			"name": "Secretaria de Turismo e Eventos",
			"phone": "(47) 3348-1080"
		},
		{
			"name": "Secretaria Executiva",
			"phone": "(47) 3341-6001"
		},
		{
			"name": "SEMASA",
			"phone": "(47) 3344-9000"
		},
		{
			"name": "Superintendência do Porto de Itajaí",
			"phone": "(47) 3341-8000"
		},
		{
			"name": "Tecnologia",
			"phone": "(47) 3341-6090"
		}
	]

	return (
		<div className="pages-Contact">
			
			{
				
				menuMobile ? 
				
				(
				
					<HeaderMobile />
					
				) 
				
				: 
				
				(

					<Header 
						search 
						route={` Contato`}
					/>
					
				)
				
			}
			
			{
				
				mobile && 
				
				<SearchMobile />
				
			}

			<TitlePage title={'Lista de telefones da Prefeitura de Itajaí'}/>
			
			<div className="pages-Contact-container">
			
				<div className="pages-Contact-destaque">
					<div className="pages-Contact-destaque-titulo">ATENDIMENTO GERAL</div>
					<div className="pages-Contact-destaque-telefone">(47) 3341-6000</div>
				</div>
				
				<ListContact items={contactList} />
				
			</div>
			
			<MapSite />
			
			<Footer />
			
			{
				
				visible && 
				
				(
				
					<VLibras forceOnload />
					
				)
				
			}
			
		</div>
		
	)
	
}