import { useEffect, useState } from "react"
import { IMenuItem } from "../../../../interfaces/menu"
import { AdminIcon } from "../../../icons/common/admin"
import { CityIcon } from "../../../icons/common/city"
import { PressIcon } from "../../../icons/common/pressOffice"
import { TourismIcon } from "../../../icons/common/tourism"
import { SubItemMenuNav } from "../subItemMenuNav"
import { ContactIcon } from "../../../icons/common/contact"
import { SubMenu } from "../subMenu"
import './styles.css'
import { useApp } from "../../../../context/app"

interface Props {
	itemMenu: IMenuItem
	idActual?: number
	onChange: (id: number) => void
	count: number
}

interface IIcon {
	title: string
	icon: JSX.Element
}

export function ItemMenuNav({ itemMenu, idActual, onChange, count }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [modal, setModal] = useState<boolean>(false)
	const [selected, setSelected] = useState<boolean>(false)

	const iconList: IIcon[] = [
		{
			title: 'Administração',
			icon: <AdminIcon />
		},
		{
			title: 'Cidade',
			icon: <CityIcon />
		},
		{
			title: 'Imprensa',
			icon: <PressIcon />
		},
		{
			title: 'Turismo',
			icon: <TourismIcon />
		},
		{
			title: 'Ouvidoria',
			icon: <ContactIcon />
		},
		{
			title: 'Horários do Transporte Público',
			icon: <CityIcon />
		},
		{
			title: 'Semasa',
			icon: <CityIcon />
		},
		{
			title: 'Contato',
			icon: <ContactIcon />
		}

	]

	const getIcon = (title: string) => {

		const indexIcon = iconList.findIndex((item) => item.title == title)

		if (indexIcon > -1) {
			return iconList[indexIcon].icon
		}
		return <></>
	}

	const handleOpenModal = () => {
		if (modal) {
			setSelected(false)
			setModal(false)
		}
		onChange(itemMenu?.cod_menu_secao || itemMenu?.cod_menu_secao_grupo || 0)
	}

	const handleCloseModal = () => {
		setSelected(false)
		setModal(false)
		onChange(0)
	}

	useEffect(() => {
		if (itemMenu.cod_menu_secao == idActual || itemMenu.cod_menu_secao_grupo == idActual) {
			setSelected(true)
			setModal(true)
		} else {
			setSelected(false)
			setModal(false)
		}
	}, [idActual])

	//console.log( 'ItemMenuNav ln 100 - count: ', count );

	return (

		<div className="hover-submenu-container">

			<div className="hover-submenu-action">

				<div
					onClick={handleOpenModal}
					className={"Header-btn"}
				>
					{getIcon(itemMenu.titulo || 'Cidade')}
					{itemMenu.titulo}
				</div>

				<div
					id="subMenu"
					className={"hover-submenu-box"}
				>

					<div className="hover-submenu-item">

						{
							itemMenu.secoes && itemMenu.secoes.map(
								(item, index) => <SubItemMenuNav key={index} section={item} />
							)
						}

						{
							itemMenu.menus &&
							<>
								{

									itemMenu.menus?.map((item, index) => {

										//console.log('Header-itemMenuNav ln 151 - item:')
										//console.log(item)

										let itemMenuNav_link = '#'

										if (
											item.link
											&& (
												item.ref_cod_menu_tipo_componente == 13
											)
										) { itemMenuNav_link = item.link }


										if (item.link === 'noticias') { itemMenuNav_link = '/noticias' }
										if (item.link === 'contato') { itemMenuNav_link = '/contato' }
										if (item.link === 'localizacao') { itemMenuNav_link = '/localizacao' }
										if (item.link == 'telefones') { itemMenuNav_link = '/telefones' }

										if (
											item.ref_cod_artigo
											&& (
												item.ref_cod_menu_tipo_componente == 2
												|| item.ref_cod_menu_tipo_componente == 12
												|| item.ref_cod_menu_tipo_componente == 14
											)
										) { itemMenuNav_link = domain + 'e/' + item.ref_cod_artigo }

										if (
											item.ref_cod_entidade
											&& (
												item.ref_cod_menu_tipo_componente == 15
												|| item.ref_cod_menu_tipo_componente == 19
											)
										) { itemMenuNav_link = domain + 'a/' + item.ref_cod_entidade }

										//console.log('Header-itemMenuNav ln 178 - itemMenuNav_link:')
										//console.log(itemMenuNav_link)

										let itemMenuNav_target = '_self'
										if (item.nova_janela == true) { itemMenuNav_target = '_blank' }

										return (

											<div key={index}>

												{

													item.subMenus ?

														<SubMenu key={index} subMenu={item} />

														:

														<a
															href={`${itemMenuNav_link}`}
															target={`${itemMenuNav_target}`}
															className="hover-submenu-item-itemMenuNav"
														>
															• {item.titulo}
														</a>

												}

											</div>

										)

									})

								}

							</>
						}

					</div>

				</div>

			</div>

		</div>

	)

}