import { useApp } from "../../../../context/app";
import { INews } from "../../../../interfaces/news";
import { parseDate } from "../../../../utils/date";

interface Props {
	news: INews
}

export function SideTopNews({ news }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const link_montado = news.title
		.toLowerCase()
		.replaceAll(' ', '-')
		.replaceAll('/', '-')
		.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, "")

	const SideTopNews_img = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/' + news.img

	//console.log('news: ', news)

	return (

		<a
			href={`${domain}noticias/${news.newsId}/${link_montado}`}
			className={!!news.img ? "SideTopNews" : "SideTopNews SideTopNews-noImg"}
		>

			{
				!!news.img &&

				<img
					src={SideTopNews_img}
					className="SideTopNews-img"
				/>

			}

			<div className="SideTopNews-container">

				<div className="SideTopNews-box">

					<div className="SideTopNews-titulo">{news.title}</div>

					<div className="SideTopNews-data">{parseDate(news.published)}</div>

					<div className="SideTopNews-resumo">

						{news.subtitle}

					</div>

				</div>

			</div>

		</a>

	)

}