import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { AppProvider } from './context/app';
import { App } from './App';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<HelmetProvider>
			<AppProvider>
				<App />
			</AppProvider>
		</HelmetProvider>
	</React.StrictMode>
);

reportWebVitals();
