import { useEffect, useState } from 'react';
import { api, urls } from "../../services/api"
import { IAcessoRapidoItem } from '../../interfaces/acessoRapido';
import { useApp } from '../../context/app';
import { TitlePage } from "../TitlePage";
import './style.css';

export function AcessoRapido() {

	const [acessoRapidoItem, setAcessoRapido] = useState<IAcessoRapidoItem[]>([])
	const [index, setIndex] = useState(0);
	const { mobile } = useApp()

	const handleSelect = (selectedIndex: any) => {
		setIndex(selectedIndex);
	};

	useEffect(() => {
		(async () => {
			try {

				const response = await api.get<IAcessoRapidoItem[]>(urls.AcessoRapido)

				//console.log('AcessoRapido ln 25 - response.data:', response.data)

				setAcessoRapido(response.data)

			} catch (error) {
				console.log('ERRO: AcessoRapido ln 29 - response.data:', error)
			}
		})()
	}, [])

	//console.log('acessoRapidoItem:', acessoRapidoItem)

	return (

		<>

			{

				!mobile &&

				<div className="AcessoRapido-titulo-box">
					<div className="AcessoRapido-titulo-container">
						<span className="AcessoRapido-titulo-bar" />
						<span className="AcessoRapido-titulo">Acesso Rápido</span>
						<span className="AcessoRapido-titulo-bar" />
					</div>
				</div>

			}

			{

				mobile &&

				<div className="AcessoRapido-titulo-box">
					<TitlePage title="ACESSO RÁPIDO" />
				</div>

			}

			<div className="AcessoRapido">

				<div className="AcessoRapido-container">

					{

						acessoRapidoItem.map((item, index) => {

							let set_retirar_lente = ''
							if (item.retirar_lente) { set_retirar_lente = 'retirar_lente' }

							let set_cor = ''
							if (!!item.cor) { set_cor = item.cor }

							let AcessoRapido_card_classe = 'AcessoRapido-card ' + set_retirar_lente + ' ' + set_cor

							let set_imagem_fundo = ''
							if (!!item.imagem_fundo) { set_imagem_fundo = item.imagem_fundo }

							return (
								<div
									className={AcessoRapido_card_classe}
									style={{
										backgroundImage: "url(" + set_imagem_fundo + ")"
									}}
								>
									<a
										href={item.url}
										target={item.target ? "_blank" : "_self"}
									>
										<div className="AcessoRapido-card-titulo">{item.titulo}</div>
										<div className="AcessoRapido-card-txt">{item.descricao}</div>
									</a>
								</div>
							)
							
						})
						
					}
					
				</div>

			</div>

		</>

	)

}