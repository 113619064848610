import { useApp } from "../../../../context/app";
import { INews } from "../../../../interfaces/news";
import { parseDate } from "../../../../utils/date";

interface Props {
	news: INews
}

export function CardMobileNews({ news }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const link_montado = news.title
		.toLowerCase()
		.replaceAll(' ', '-')
		.replaceAll('/', '-')
		.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, "")

	const CardMobileNews_img = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/foto/' + news.img

	//console.log('CardMobileNews.tsx ln 19 - link_montado')
	//console.log(link_montado)

	return (

		<a
			href={`${domain}noticias/${news.newsId}/${link_montado}`}
			className="News-Mobile-CardMobileNews"
		>

			{

				!!news.img &&

				<img
					src={CardMobileNews_img}
					className="News-Mobile-CardMobileNews-img"
				/>

			}

			<div className="News-Mobile-CardMobileNews-campo" >
				<div className="News-Mobile-CardMobileNews-titulo">{news.title}</div>
				<div className="News-Mobile-CardMobileNews-data">{parseDate(news.published)}</div>
			</div>

		</a>

	)

}