import { useApp } from "../../../context/app"
import { IMenu } from "../../../interfaces/menu"

interface Props {
    newsCategorie: IMenu
    idActual: number
    onClick?: () => void
}
export function ItemCategorie({ idActual, newsCategorie, onClick }: Props) {
    const { configDomainURL } = useApp()
    const domain = configDomainURL
    if (!newsCategorie.ref_cod_artigo && !newsCategorie.ref_cod_entidade) {

        return (

            <a
                href={newsCategorie.link}
                target="_blank"
                className={newsCategorie.ref_cod_entidade == idActual ? "pages-Entity-itemCategorie-generico on" : "pages-Entity-itemCategorie-generico"}
                onClick={onClick}
            >{newsCategorie.titulo}</a>

        )

    }

    return (

        <a
            href={`${domain}${newsCategorie.ref_cod_artigo ? 'e/' + newsCategorie.ref_cod_artigo : 'a/' + newsCategorie.ref_cod_entidade}`}
            className={newsCategorie.ref_cod_entidade == idActual ? "pages-Entity-itemCategorie on" : "pages-Entity-itemCategorie"}
            onClick={onClick}
        >{newsCategorie.titulo}</a>

    )

}