import { useEffect, useState } from "react"
import { IMenuItem, ISection } from "../../../../interfaces/menu"
import { AdminIcon } from "../../../icons/common/admin"
import { CityIcon } from "../../../icons/common/city"
import { PressIcon } from "../../../icons/common/pressOffice"
import { TourismIcon } from "../../../icons/common/tourism"
import { SubItemMenuNav } from "../subItemMenuNav"
import { ContactIcon } from "../../../icons/common/contact"

interface Props {
	itemMenu: IMenuItem
	idActual?: number
	onChange: (id: number) => void
}

interface IIcon {
	title: string
	icon: JSX.Element
}

export function ItemMenuNav({ itemMenu, idActual, onChange }: Props) {

	const [modal, setModal] = useState<boolean>(false)
	const [selected, setSelected] = useState<boolean>(false)

	const iconList: IIcon[] = [
		{
			title: 'Administração',
			icon: <AdminIcon />
		},
		{
			title: 'Cidade',
			icon: <CityIcon />
		},
		{
			title: 'Imprensa',
			icon: <PressIcon />
		},
		{
			title: 'Turismo',
			icon: <TourismIcon />
		},
		{
			title: 'Ouvidoria',
			icon: <ContactIcon />
		},
		{
			title: 'Horários do Transporte Público',
			icon: <CityIcon />
		},
		{
			title: 'Semasa',
			icon: <CityIcon />
		},
		{
			title: 'Contato',
			icon: <ContactIcon />
		}
	]

	const getIcon = (title: string) => {
		const indexIcon = iconList.findIndex((item) => item.title == title)
		if (indexIcon > -1) {
			return iconList[indexIcon].icon
		}
		return <></>
	}

	const handleOpenModal = () => {
		if (modal) {
			setSelected(false)
			setModal(false)
			onChange(itemMenu?.cod_menu_secao || itemMenu?.cod_menu_secao_grupo || 0)
		}
		onChange(itemMenu?.cod_menu_secao || itemMenu?.cod_menu_secao_grupo || 0)
	}

	useEffect(() => {

		if (
			itemMenu.cod_menu_secao == idActual
			|| itemMenu.cod_menu_secao_grupo == idActual
		) {

			setSelected(true)
			setModal(true)

		}
		else {

			setSelected(false)
			setModal(false)

		}

	}, [idActual])

	const renderNivel01 = (subitem: any) => {

		return subitem.subMenus?.map((nivel01: any) => {
			
			console.log( 'nivel01', nivel01 );
			
			return (
				
				<>
				
					{
						!!nivel01.link &&
						
						<a
							href={nivel01.link}
							className="HeaderMobile-sidenav-item-li-a"
						>{nivel01.titulo}</a>
						
					}
					
					{
						!nivel01.link &&
						!!nivel01.ref_cod_artigo &&
						
						<a
							href={'/e/'+ nivel01.ref_cod_artigo}
							className="HeaderMobile-sidenav-item-li-a"
						>{nivel01.titulo}</a>
						
					}
					
					{
						!nivel01.link &&
						!!nivel01.ref_cod_entidade &&
						
						<a
							href={'/a/'+ nivel01.ref_cod_entidade}
							className="HeaderMobile-sidenav-item-li-a"
						>{nivel01.titulo}</a>
						
					}
					
				</>
			)

		})

	}

	const renderSubmenu = (item: any) => {

		if (item.subMenus == undefined) {

			return (

				<a href={item.link}>{item.titulo}</a>

			)

		}

		if (!!item.subMenus) {
			
			//console.log( 'item', item );

			return (

				<>

					<a
						href={item.link}
						className="HeaderMobile-sidenav-item-nivel01"
					>{item.titulo}</a>

					{renderNivel01(item)}

				</>

			)

		}

	}

	//console.log('itemMenu:', itemMenu)

	return (

		<>

			<div
				onClick={handleOpenModal}
				className="HeaderMobile-sidenav-item"
				style={{
					backgroundColor: selected ? 'var(--preto_lente01)' : '',
				}}
			>
				{getIcon(itemMenu.titulo || 'Cidade')}
				{itemMenu.titulo}
			</div>

			{

				modal &&

				<div className="HeaderMobile-sidenav-item-box">

					{

						itemMenu.secoes && itemMenu.secoes.map((item, index) =>

							<SubItemMenuNav
								key={index}
								section={item}
							/>

						)

					}

					{

						itemMenu.menus &&

						<ul>

							{

								itemMenu.menus?.map((item, index) => {
									
									//console.log( 'item', item )
									
									return (

										<li
											key={index}
											className="HeaderMobile-sidenav-item-li"
										>

											{renderSubmenu(item)}

										</li>

									)

								})

							}

						</ul>

					}

				</div>

			}

		</>

	)

}