import celular from '../../assets/imgs/celularConectai_app.webp'
import apple from '../../assets/imgs/conectai-APPLE-appstore-qr-white.png'
import android from '../../assets/imgs/conectai-GOOGLE-playstore-qr-white.png'
import './style.css'

export function Conectai() {
	
	return (
	
		<div className="Conectai">
		
			<div className="Conectai-container">
			
				<img src={celular} className="Conectai-telefone"/>
				
				<div className="Conectai-col">
				
					<a
						href='https://conectai.itajai.sc.gov.br/'
						target='_blank'
						className="Conectai-btn"
					>Aplicativo Conecta.í</a>
					
					<div className="Conectai-titulo">Baixe o App <br/>Conecta.í</div>
					
					<a 
						href='https://apps.apple.com/br/app/conecta-%C3%AD/id1315730380'
						target='_blank'
					>
						<img src={apple} className="Conectai-app-btn apple"/>
					</a>
					
					<a 
						href='https://play.google.com/store/apps/details?id=br.gov.sc.itajai.digital'
						target='_blank'
					>
						<img src={android} className="Conectai-app-btn" />
					</a>
					
				</div>
				
			</div>
			
		</div>
		
	)
	
}