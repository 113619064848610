import { createContext, useContext, useEffect, useState } from "react"
import useWindowDimensions from "../hooks/Dimensions"
import { api } from "../services/api"

interface IAppContext {
	mobile: boolean
	visible: boolean
	setVisible: React.Dispatch<React.SetStateAction<boolean>>
	menuMobile: boolean
	inputSearch: string
	smallScreen: boolean
	setInputSearch: React.Dispatch<React.SetStateAction<string>>
	configDomainURL: string
	basename: string
	configApiURL: string
}


const AppContext = createContext({} as IAppContext)

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
	const configDefault = require("../ini.json")
	const [basename, setBasename] = useState("")
	const [configDomainURL, setConfigDomainURL] = useState(configDefault.domain_url)
	const [configApiURL, setConfigApiURL] = useState(configDefault.api_url)
	const [mobile, setMobile] = useState<boolean>(false)
	const [menuMobile, setMenuMobile] = useState<boolean>(false)
	const [smallScreen, setSmallScreen] = useState<boolean>(false)
	const [inputSearch, setInputSearch] = useState<string>('')
	const { width } = useWindowDimensions()
	const [visible, setVisible] = useState<boolean>(false)

	const tamanho_mobile = 1024
	const tamanho_tablet = 1300

	useEffect(() => {

		if (tamanho_mobile > width) {

			setMobile(true)
			console.log('É mobile')

		} else {

			setMobile(false)

		}

	}, [width]);

	useEffect(() => {

		// if ( tamanho_tablet > width && tamanho_mobile <= width ) {
		if (tamanho_mobile <= width) {

			setSmallScreen(true)

		} else {

			setSmallScreen(false)

		}

	}, [width]);

	useEffect(() => {

		if (tamanho_mobile > width) {

			setMenuMobile(true)

		} else {

			setMenuMobile(false)

		}

	}, [width]);

	useEffect(() => {

		(async () => {

			const response = await fetch(`${window.location.hostname === 'localhost' ? 'http://localhost:3000' : window.location.hostname === 'appdev' ? "http://appdev/portais/portal-municipio/deploy" : `https://${window.location.hostname}`}/ini.json`, {
				headers: {
					Accept: "application/json"
				}
			})
			const configJson = await response.json()
			api.defaults.baseURL = `${configJson.api_url}portaladm-pmitajai/api/`
			setConfigApiURL(configJson.api_url)
			setConfigDomainURL(configJson.domain_url)
			setBasename(configJson.basename)
		})()

	}, [])



	return (

		<AppContext.Provider
			value={{
				mobile,
				menuMobile,
				smallScreen,
				setVisible,
				visible,
				inputSearch,
				setInputSearch,
				configDomainURL,
				basename,
				configApiURL
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export function useApp() {

	const context = useContext(AppContext)
	return context

}

