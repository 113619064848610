import { useEffect, useState } from "react"
import { useApp } from "../../context/app"
import { GridIcon } from "../icons/common/grid"
import { ListIcon } from "../icons/common/list"
import "./style.css"

interface Props {
	title: string
	iconNews?: boolean
	onChange?: (value: boolean) => void
}

export function TitlePage({ title, onChange, iconNews = false }: Props) {
	
	const { mobile } = useApp()
	const [listGrid, setListGrid] = useState<boolean>(true)
	const [pagina_atual, setPaginaAtual] = useState<string>('')

	useEffect(() => {
		if (onChange) {
			onChange(listGrid)
		}
	}, [listGrid])
	
	useEffect(() => {
		
		const paginaAtualArray = window.location.pathname.split("/")
		
		//console.log( 'paginaAtualArray[1]', paginaAtualArray[1] )
		
		setPaginaAtual( paginaAtualArray[1] )
		
	}, [])
	
	//console.log( 'pagina_atual', pagina_atual )

	return (
	
		<div className="TitlePage">
		
			{
				
				<div className="TitlePage-iconNews">
				
					<div className="TitlePage-iconNews-titulo">{title}</div>
					
					{
						
						pagina_atual == 'noticias' &&
						
						<div 
							className="TitlePage-iconNews-icone"
							onClick={() => setListGrid(!listGrid)}
						>
						
							{
								
								listGrid ?

								<GridIcon />

								:

								<ListIcon />
								
							}
							
						</div>
						
					}
					
				</div>
				
			}

			<div className="TitlePage-bottom">
				<div className="TitlePage-bottom-amarelo"/>
				<div className="TitlePage-bottom-bar"/>
			</div>
			
		</div>
		
	)
	
}